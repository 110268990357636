import React from "react";
import styled from "styled-components";
import TicTacToe from "./components/TicTacToe";

function App() {
  return (
    <Main>
      <TicTacToe />
    </Main>
  );
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const height = "calc(var(--vh, 1vh) * 100)";
const colorMint = "#52d2bc";

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  height: ${height};
  background: black;
  border: 4px solid ${colorMint};
  @media screen and (min-width: 480px) {
    border: 0;
  }
`;

export default App;
