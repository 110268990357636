import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { border } from "../styles";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0, 0.6)"
  }
};

export const ResultModal = ({ isOpen, close, startNewGame, winner }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={close}
      style={customStyles}
      ariaHideApp={false}
    >
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>Peli on päättynyt</ModalTitle>
        </ModalHeader>
        <ModalContent>{winner}</ModalContent>
        <ModalFooter>
          <Button onClick={startNewGame}>Pelaa uudelleen</Button>
          <Button onClick={close}>Tutustu Kuubiin</Button>
        </ModalFooter>
      </ModalWrapper>
    </StyledModal>
  );
};

const colorMint = "#52d2bc";

const StyledModal = styled(Modal)`
  height: 35rem;
  position: relative;
  margin: 0 auto;
  top: 45%;
  transform: translateY(-50%);
  right: auto;
  bottom: auto;
  width: 320px;
  outline: none;
  display: flex;
  flex-direction: column;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: black;
  backface-visibility: hidden;
  padding: 4rem 2rem;
  ${border};
`;

const ModalHeader = styled.div`
  width: 100%;
`;

const ModalTitle = styled.h1`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: ${colorMint};
`;

const ModalContent = styled.p`
  text-align: center;
  color: ${colorMint};
  white-space: pre-line;
  line-height: 2.4rem;
  font-size: 2rem;
`;
ModalContent.displayName = "ModalContent";

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.button`
  margin: 2rem 0;
  font-size: 16px;
  text-align: center;
  color: ${colorMint};
  padding: 1rem 2rem;
  background: transparent;
  ${border};
  cursor: pointer;

  &:last-child {
    margin: 0 0;
  }
`;
