import React from "react";
import styled from "styled-components";
import Logo from "../assets/kuubi_logo.png";

const Header = () => {
  return (
    <HEADER>
      <LOGO></LOGO>
      <HeaderText>Voitatko Kuubikissan ristinollassa?</HeaderText>
    </HEADER>
  );
};

const colorMint = "#52d2bc";

const HEADER = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0;

  img {
    width: 45%;
    margin: 2rem auto;
  }
`;

const LOGO = styled.div`
  width: 20rem;
  height: 20rem;
  margin: 2rem 0;
  background-image: url(${Logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const HeaderText = styled.h1`
  text-align: center;
  color: ${colorMint};
`;
export default Header;
